export const step: any = [
    [
        'name',
        'middle_name',
        'last_name',
        'mother_last_name',
        'email',
        'phone',
        'birthday',
        'gender',
        'country',
        'state',
        'nationality',
        'residence',
        'rfc',
        'curp',
    ],
    [
        'zip',
        'municipality',
        'state',
        'city',
        'locality',
        'street_type',
        'street',
        'street_number',
    ],
    ['occupation', 'education'],
    [
        ['national_front', 'national_back', 'passport'],
        ['proof_residency', 'residence_front', 'residence_back'],
    ],
];
