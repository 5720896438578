import { IUserData } from '../../@types/user';
import { step } from '../../utils/options/steps';

export const handleCheckRealStep = (data: IUserData) => {
    let realStep = {
        general: false,
        riskForm: false,
        address: false,
        experience: false,
        documentation: false,
        beneficiary: false,
        taxes: false,
    };

    let flagGeneral = false;
    step[0].map((el: string) => {
        if (data.hasOwnProperty(el) && data[el] != '') {
            flagGeneral = true;
        } else {
            flagGeneral = false;
        }
    });

    realStep.general = flagGeneral;

    if (data.investor.riskForm) {
        realStep.riskForm = true;
    }

    let flagAddress = false;
    step[1].map((el: string) => {
        if (
            data.investor.address.hasOwnProperty(el) &&
            data.investor.address[el] != ''
        ) {
            flagAddress = true;
        } else {
            flagAddress = false;
        }
    });

    realStep.address = flagAddress;

    let flatExperience = false;
    step[2].map((el: string) => {
        if (
            data.investor.experience.hasOwnProperty(el) &&
            data.investor.experience[el] != ''
        ) {
            flatExperience = true;
        } else {
            flatExperience = false;
        }
    });
    realStep.experience = flatExperience;

    let flagDocs = false;
    if (data.foreign) {
        const array = step[3];
        array[1].map((el: string) => {
            if (
                data.investor.documentation.hasOwnProperty(el) &&
                data.investor.documentation[el]
            ) {
                flagDocs = true;
            } else {
                flagDocs = false;
            }
        });
    } else {
        const array = step[3][0];
        if (
            data.investor.documentation.hasOwnProperty(array[0]) &&
            data.investor.documentation[array[0]]
        ) {
            if (
                data.investor.documentation.hasOwnProperty(array[1]) &&
                data.investor.documentation[array[1]]
            ) {
                flagDocs = true;
            }
        } else if (
            data.investor.documentation.hasOwnProperty(array[2]) &&
            data.investor.documentation[array[2]]
        ) {
            flagDocs = true;
        } else {
            flagDocs = false;
        }
    }

    realStep.documentation = flagDocs;

    if (data.investor.beneficiary.length > 0) {
        realStep.beneficiary = true;
    }

    if (data.investor.taxes.taxSystem && data.investor.taxes.taxSystem != '') {
        realStep.taxes = true;
    }

    return realStep;
};


